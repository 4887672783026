@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* { box-sizing: border-box; }

.App {
  font-family: 'Roboto', sans-serif;
}

ul {
  margin: 0;
  padding-left: 0;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

ul li {
  font-weight: bold;
  font-size: 21px;
  /* padding-left: 20px; */
}

a {
  color: black;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

body {
  background-color: #f4f4f4;
  max-width: 100vw;
}

.container {
  background-color: #FFFFFF;
  border-radius: 15px;
  margin: 2rem;
  display: flex;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.side-left {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem;
}

.side-left h1{
  margin: 0;
  width: 90%;
  font-size: 48px;
  line-height: 3.5rem;
}

.side-left h3{
  margin: 0;
  width: 95%;
  padding: 1rem 0;
  font-size: 21px;
}

.video-apresentacao {
  margin: 2rem 0;
  display: flex;
}

.video-youtube {
  width: 650px;
  height: 366px;
}

.video-youtube iframe{
  border-radius: 15px;
}

.side-right {
  padding: 2rem 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-logo {
  width: 150px;
  height: 150px;
}

.image-logo img {
  width: 100%;
  height: 100%;
}

.side-right h2 {
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  text-align: center;
}

.side-right p {
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  text-align: center;
  font-size: 21px;
  font-weight: 500;
}

.input-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-form label {
  margin: 0;
  margin-bottom: 3px;
  padding: 0;
  font-size: 12px;
  color: #9e9e9e;
}

.input-right {
  margin-bottom: 1rem;
  height: 2.5rem;
  width: 100%;
  padding-left: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: black;
}

.checkbox-div {
  margin-top: 1rem;
}

.side-right button {
  margin-top: 1rem;
  height: 2.5rem;
  width: 100%;
  padding-left: 1rem;
  background-color: #009e2a;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.side-right button:hover {
  opacity: .6;
}

.div-vhsys {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-interrogacao {
  width: 1rem;
  height: 1rem;
}

.icon-interrogacao img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
  }

  .side-left h1{
    font-size: 21px;
    line-height: 2rem;
    text-align: center;
  }

  .video-youtube {
    width: 700px;
    height: 150px;
  }

  .side-left h3{
    font-size: 18px;
    text-align: center;
  }

  ul li {
    font-size: 18px;
    text-align: center;
    /* padding-left: 20px; */
  }

  

}