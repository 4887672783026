.saiba-mais {
    background-color: #FFF;
    width: 30rem;
    height: 90vh;
    padding: 2rem;
    overflow: auto;
    position: fixed;
    display: flex;
    flex-direction: column;
  }
  
  .close-saiba-mais {
    color: #ff0a53;
    margin-bottom: 1rem;
  }
  
  .title-saiba-mais {
    color: #2567B7;
    font-size: 18px;
    font-weight: bold;
  }

  .video-saiba-mais {
      width: 100%;
  }

  .video-saiba-mais iframe{
    width: 100%;
    height: 250px;
  }

  .image-saiba-mais {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .image-saiba-mais img{
    width: 300px;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    .saiba-mais {
      width: 100vw;
      height: 90vh;
    }

    .video-saiba-mais {
        margin-bottom: 2rem;
    }
  }